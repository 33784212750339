import * as tipping_tap from '../actions/tippping_tap';

const initialState = {
  isFetching: false,
  devices: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case tipping_tap.GET_DEVICES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case tipping_tap.GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        isFetching: false,
      };
    case tipping_tap.GET_DEVICES_FAIL:
      return {
        ...state,
        devices: [],
        isFetching: false,
      };
    default:
      return state
  }
}