import ApiHelper from '../../helpers/apiHelper';

export const GET_DEVICES_REQUEST = 'tipping_tap/GET_DEVICES_REQUEST';
export const GET_DEVICES_SUCCESS = 'tipping_tap/GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAIL = 'tipping_tap/GET_DEVICES_FAIL';


export const getDevices = (customerId, userType) => dispatch => {
  dispatch({type: GET_DEVICES_REQUEST});
  let url = `/devices?pageSize=100&page=0`;
  if (userType === 'TENANT_ADMIN') {
    url = '/tenant' + url;
  } else {
    url = '/customer/' + customerId + url;
  }

  return ApiHelper.get(url)
    .then(res => {
      let data = res.data.data;
      dispatch({type: GET_DEVICES_SUCCESS, payload: data})
    }).catch(err => {
      dispatch({type: GET_DEVICES_FAIL});
    });
};