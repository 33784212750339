import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {reducer as toastr} from 'react-redux-toastr';
import auth from './auth';
import tipping_tap from './tipping_tap';

export default combineReducers({
  router: routerReducer,
  auth,
  tipping_tap,
  toastr,
});
